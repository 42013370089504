<template>
  <div>
    <b-modal content-class=""
             id="modal-term-and-conditainals"
             size="lg"
             centered
             scrollable
             no-close-on-backdrop
    >

      <div slot="modal-title">

        <div class="small">By clicking, registering and submitting post to this microsite web link, you agree to the
          following terms and disclaimer: <br><br>
        </div>

        <div class="notice-header">
          Terms and Disclaimer
        </div>
      </div>

      <div class="notice">


        The information in this virtual lobby is not intended to form the basis of any investment decision with respect
        to MISC Berhad (“MISC”) and shall not form the basis of or to be relied upon in connection with any contract or
        commitment whatsoever. <br><br>

        No representation or warranty, express or implied, is or will be made by MISC in relation to the accuracy and
        completeness of the information made available and any liability therefore is expressly disclaimed.  <br><br>

        Inappropriate language will not be tolerated and image posts containing discriminatory, racist, offensive,
        obscene, vulgar, profane, inflammatory, unlawful or otherwise inappropriate and/or objectionable statements,
        language or content will have their postings and privileges edited or removed.  <br><br>

        Although MISC believes that the expectations of its management as reflected by forward-looking statements
        contained in this virtual lobby are reasonable based on information currently available to it, no assurances can
        be given that such expectations will materialise as these are dependent on risks, uncertainties and other
        factors which in many cases are beyond MISC’s control.  <br><br>

        All title, right and interest in intellectual property rights of whatsoever nature in respect of or connected to
        this virtual lobby shall vest and remain with MISC and shall not be used in any way without the prior written
        consent of MISC.  <br><br>

        All contents in this virtual lobby are STRICTLY CONFIDENTIAL, FOR INTERNAL USE ONLY, AND MUST NOT BE RECORDED,
        TRANSFERRED OR DISCLOSED (including the microsite web link to this virtual lobby) WITH ANY EXTERNAL PARTY
        without the prior written consent of MISC.  <br><br>


        [For any enquiry, kindly liaise with Group Corporate Communication at gcc_digital@miscbhd.com]
      </div>

      <div slot="modal-footer">
        <div class="text-center">
          <div class="btn-register font-weight-bold mb-2" @click="save(true)">AGREE</div>
          <div class="cancel" @click="save(false)">CANCEL</div>
        </div>

      </div>


    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'TermAndConditainalsDialog',
    methods: {
      save(agree) {
        this.$bvModal.hide('modal-term-and-conditainals');
        this.$emit('agree', agree)
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/css/colors";

  .notice-header {
    font-family: Arial, sans-serif;
    line-height: 1.2;
  }

  .notice {
    font-family: Arial, sans-serif;
  }


  .modal-header .close {
    color: $navy;
    opacity: 1;
    padding: 0;
    margin: 0;
  }

  .modal-header, .modal-content, .modal-footer {
    border-radius: 0;
    border: none;
  }

  .modal-footer {
    display: unset;
  }

  .notice-header {
    color: $navy;
    font-weight: bold;
  }

  .modal-content {
    border: 5px solid $navy;
  }


  .modal-body::-webkit-scrollbar {
    width: 8px;
  }

  .modal-body::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    border-radius: 8px;
  }

  .cancel {
    cursor: pointer;
    text-decoration: underline;
    color: $gray;
  }

</style>
