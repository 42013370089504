<template>
    <div class="register-container">
        <div class="container">

            <div class="row justify-content-center align-items-center pb-5 pt-5" style="min-height: 100vh">
                <div class="col-auto" style="max-width: 330px">
                    <div class="title">
                        WELCOME TO MISC PRESIDENT/<br>
                        GROUP CEO's TOWNHALL 2021
                    </div>

                    <div class="form-group">
                        <input class="form-input" placeholder="NAME" v-model="form.name">
                        <error-message class="mt-1" :msg="errorMessage.name"></error-message>
                    </div>

                    <div class="form-group">
                        <input class="form-input" placeholder="STAFF ID" v-model="form.staff_id">
                        <error-message class="mt-1" :msg="errorMessage.staff_id"></error-message>
                    </div>

                    <div class="form-group">
                        <b-dropdown
                                v-model="form.department"
                                :text="form.department || 'COMPANY'"
                                variant="transparent"
                                class="form-input w-100">

                            <b-dropdown-item v-for="option in company"
                                             :key="option.company"
                                             :value="option.company"
                                             @click="form.department = option.company"
                            >
                                {{option.company}}
                            </b-dropdown-item>
                        </b-dropdown>


                        <error-message class="mt-1" :msg="errorMessage.department"></error-message>
                    </div>

                    <div class="form-group">
                        <input class="form-input" placeholder="COMPANY EMAIL" v-model="form.email">
                        <error-message class="mt-1" :msg="errorMessage.email"></error-message>
                    </div>

                    <div class="form-group">
                        <vue-tel-input class="form-input mobile-no"
                                       placeholder="MOBILE NUMBER"
                                       v-model="form.mobile_no"
                                       @onInput="setMobileNo"
                                       mode="international"
                                       :validCharactersOnly="true"
                        ></vue-tel-input>
                        <div class="small mt-1" style="line-height: 1.2;">Please select your country code and key in
                            your
                            mobile number
                        </div>
                        <error-message class="mt-1" :msg="errorMessage.mobile_no"></error-message>
                    </div>

                    <div class="form-group text-center">
                        <div>
                            <span> REQUEST OTP via </span>
                            <span class="otp-type" @click="requestOtpEmail">email</span>
                            <span>or</span>
                            <span class="otp-type" @click="requestOtpSms">mobile</span>
                        </div>

                        <transition name="slide-fade">
                            <div class="text-center mb-3 form-group" v-if="otp_message">
                                {{otp_message}}
                            </div>
                        </transition>
                    </div>

                    <div class="form-group">
                        <input class="form-input" v-model="form.otp_code" placeholder="INSERT OTP">
                        <error-message class="mt-1" :msg="errorMessage.otp_code"></error-message>
                    </div>

                    <div class="form-group">
                        <div class="term-and-conditions" @click="readTermAndConditionals"> Read the Terms and Conditions
                            here
                        </div>
                        <term-and-conditainals-dialog @agree="agree"/>

                        <div class="row">
                            <div class="col-auto">
                                <input class="inp-cbx" v-model="tof" id="cbx" type="checkbox" style="display: none;"/>
                                <label class="cbx m-0" for="cbx">
                        <span><svg width="12px" height="9px" viewbox="0 0 12 9">
                          <polyline points="1 5 4 8 11 1"></polyline>
                        </svg>
                        </span>
                                </label>
                            </div>

                            <div class="col p-0">
                                <div class="condition">
                                    I agree to the terms and conditions and understand the information about MISC's
                                    Townhall will not be shared externally.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <a href="mailto:gcc_digital@miscbhd.com">
                                    <button class="btn-login w-100">CONTACT US</button>
                                </a>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <button :disabled="!tof" class="w-100 btn-register" @click="register">ENTER</button>
                            </div>
                        </div>
                    </div>


                    <div class="form-group text-right">
                        Already registered?
                        <router-link to="/login" tag="span" class="login">login here</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '../store';
    import {GET_OTP_EMAIL, GET_OTP_SMS, REGISTER, SET_STATE} from "../store/actions.type";
    import ErrorMessage from "../components/ErrorMessage";
    import validate from 'validate.js';
    import Swal from 'sweetalert2/src/sweetalert2.js'
    import TermAndConditainalsDialog from "../components/TermAndConditainals";
    import * as _ from 'underscore';


    export default {
        components: {TermAndConditainalsDialog, ErrorMessage},
        created() {

        },
        data() {
            return {
                tof: false,
                form: {
                    name: "",
                    staff_id: "",
                    department: "",
                    email: "",
                    mobile_no: "",
                    otp_code: "",
                    otp_type: "",
                    msg_id: ""
                },
                otp_message: "",
                errorMessage: "",
                isLoading: false,
                phoneObject: {},

                company: [
                    {company: "Digimagic", domain: [ "@digimagic.com.sg", "@digimagic.com.my"]},
                    {company: "MISC", domain: ["@miscbhd.com"]},
                    {company: "ALAM", domain: ["@alam.edu.my"]},
                    {company: "MMS", domain: ["@mmssb.com.my"]},
                    {company: "MHB", domain: ["@mmhe.com.my"]},
                    {company: "Eaglestar", domain: ["@eaglestar.com.my", "@es-sea.com"]},
                    {company: "AET", domain: ["@aet-tankers.com"]},
                    {company: "Magellan X", domain: ["@magellanx.co"]},
                    {company: "Sol X", domain: ["@sol-x.co"]},
                    {company: "Spares CNX", domain: ["@sparescnx.com"]},
                    {company: "Chord X", domain: ["@chordx.co"]},
                ]
            }
        },
        methods: {
            setMobileNo(event) {
                this.phoneObject = event;
            },

            async register() {
                let errorMessage = this.validateForm('register');

                if (errorMessage) {
                    return this.errorMessage = errorMessage;
                } else {
                    this.errorMessage = {};
                }

                if (this.isLoading) return;
                this.isLoading = true;

                let {number} = this.phoneObject;
                let mobileNo = number.e164.replace(/[^0-9]/g, '');

                let result = await store.dispatch(REGISTER, {
                    name: this.form.name,
                    staff_id: this.form.staff_id,
                    department: this.form.department,
                    email: this.form.email,
                    mobile_no: mobileNo,
                    otp_code: this.form.otp_code,
                    otp_type: this.form.otp_type,
                    msg_id: this.form.msg_id
                });

                this.isLoading = false;

                if (result.token) {
                    await store.dispatch(SET_STATE, true);
                    setTimeout(async function () {
                        // register success
                        window.location.href = 'https://townhall.misc.com.my/town-hall/';
                    }, 19000);

                } else {
                    Swal.fire({
                        text: result.message,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn-primary'
                        }
                    });

                }
            },
            async requestOtpSms() {
                let errorMessage = this.validateForm('request otp sms');

                if (errorMessage) {
                    return this.errorMessage = errorMessage;
                } else {
                    this.errorMessage = {};
                }

                if (this.isLoading) return;
                this.isLoading = true;

                // reset otp message
                this.otp_message = "";

                let {number} = this.phoneObject;
                let mobileNo = number.e164.replace(/[^0-9]/g, '');

                let result = await store.dispatch(GET_OTP_SMS, {
                    mobile_no: mobileNo
                });

                this.isLoading = false;

                if (result.msg_id) {
                    this.form.msg_id = result.msg_id;
                    this.form.otp_type = 'mobile';
                    this.otp_message = `We have sent the OTP code to +${mobileNo}`;
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn-primary'
                        }
                    });
                }
            },

            async requestOtpEmail() {
                let errorMessage = this.validateForm('request otp email');

                if (errorMessage) {
                    return this.errorMessage = errorMessage;
                } else {
                    this.errorMessage = {};
                }

                if (this.isLoading) return;
                this.isLoading = true;

                // reset otp message
                this.otp_message = "";

                let result = await store.dispatch(GET_OTP_EMAIL, {
                    email: this.form.email
                });

                this.isLoading = false;

                if (result.msg_id) {
                    this.form.msg_id = result.msg_id;
                    this.form.otp_type = 'email';
                    this.otp_message = `We have sent the OTP code to ${this.form.email}`;
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn-primary'
                        }
                    });
                }
            },


            readTermAndConditionals() {
                this.$bvModal.show('modal-term-and-conditainals');
            },

            agree(agree) {
                this.tof = agree;
            },

            validateForm(formState) {
                let validateRule = null;
                let errMessageList = null;

                validate.validators.checkMobileNo = function () {
                    return this.phoneObject.valid ? null : '^ Invalid mobile number'
                }.bind(this);


                validate.validators.checkEmailDomain = function () {
                    if (this.form.email) {
                        try {

                            let data = this.form.email.toLocaleLowerCase();
                            data = data.split("@");
                            let [name, domain] = data;

                            let whiteList = _.findWhere(this.company, {company: this.form.department});
                            if (whiteList) {
                                whiteList.domain.push("@digimagic.com.sg", "@digimagic.com.my");
                                return whiteList.domain.includes(`@${domain}`) ? null : "^ Email domain not allowed"
                            } else {
                                return "^ Invalid company"
                            }

                        } catch (e) {
                            return "^ Invalid email"
                        }
                    }
                }.bind(this);


                if (formState === 'request otp sms') {
                    validateRule = {
                        mobile_no: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter mobile number"
                            },
                            checkMobileNo: {
                                message: "^Invalid mobile number"
                            }
                        }
                    };

                    errMessageList = validate(
                        {
                            mobile_no: this.form.mobile_no
                        }, validateRule);

                } else if (formState === 'request otp email') {
                    validateRule = {
                        department: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please select company"
                            }
                        },
                        email: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter email"
                            },
                            email: {
                                message: "^Invalid email"
                            },
                            checkEmailDomain: {
                                message: "^Invalid email"
                            }
                        }
                    };

                    errMessageList = validate(
                        {
                            department: this.form.department,
                            email: this.form.email
                        }, validateRule);
                } else if (formState === 'register') {
                    validateRule = {
                        name: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter name"
                            }
                        },
                        staff_id: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter staff id"
                            }
                        },
                        department: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please select company"
                            }
                        },
                        email: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter email"
                            },
                            email: {
                                message: "^Invalid email"
                            },
                            checkEmailDomain: {
                                message: "^Invalid email"
                            }
                        },
                        mobile_no: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter mobile number"
                            },
                            checkMobileNo: {
                                message: "^Invalid mobile number"
                            }
                        },
                        otp_code: {
                            presence: {
                                allowEmpty: false,
                                message: "^Please enter OTP number"
                            }
                        }
                    };

                    errMessageList = validate(
                        {
                            name: this.form.name,
                            staff_id: this.form.staff_id,
                            department: this.form.department,
                            email: this.form.email,
                            mobile_no: this.form.mobile_no,
                            otp_code: this.form.otp_code
                        }, validateRule);
                }


                if (errMessageList) {
                    const error = {};
                    for (const [key, value] of Object.entries(errMessageList)) {
                        error[key] = value[0];
                    }
                    return error;
                } else {
                    return false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/colors";

    .register-container {
        color: $primary;
        background-image: url("../assets/image/background_gate.png");
        background-position: center center;
        background-size: cover;
    }
</style>
